// User Details
export const SET_USER_DETAILS = "SET_USER_DETAILS";
// Fetch Items
export const FETCH_ITEMS_BEGIN = "FETCH_ITEMS_BEGIN";
export const FETCH_ITEMS_SUCCESS = "FETCH_ITEMS_SUCCESS";
export const FETCH_ITEMS_ERROR = "FETCH_ITEMS_ERROR";
// Cart Actions
export const ADD_CART = "ADD_CART";
export const BEGIN_SAVE_ORDER = "BEGIN_SAVE_ORDER";
export const COMPLETE_SAVE_ORDER = "COMPLETE_SAVE_ORDER";
export const ERROR_SAVE_ORDER = "ERROR_SAVE_ORDER";

// Fetch Orders
export const FETCH_ORDERS_BEGIN = "FETCH_ORDERS_BEGIN";
export const FETCH_ORDERS_SUCCESS = "FETCH_ORDERS_SUCCESS";
export const FETCH_ORDERS_ERROR = "FETCH_ORDERS_ERROR";
