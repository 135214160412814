// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-js": () => import("/tmp/f3f50bb/src/pages/About.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-admin-js": () => import("/tmp/f3f50bb/src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-blog-js": () => import("/tmp/f3f50bb/src/pages/Blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-confirm-js": () => import("/tmp/f3f50bb/src/pages/Confirm.js" /* webpackChunkName: "component---src-pages-confirm-js" */),
  "component---src-pages-index-js": () => import("/tmp/f3f50bb/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-order-summary-js": () => import("/tmp/f3f50bb/src/pages/OrderSummary.js" /* webpackChunkName: "component---src-pages-order-summary-js" */),
  "component---src-pages-price-js": () => import("/tmp/f3f50bb/src/pages/Price.js" /* webpackChunkName: "component---src-pages-price-js" */),
  "component---src-pages-start-js": () => import("/tmp/f3f50bb/src/pages/Start.js" /* webpackChunkName: "component---src-pages-start-js" */)
}

